import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ReservationPrices } from 'app/model/entity/ticket-editor/reservation-price';
import { CommonService } from 'app/service/common.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { DialogExportReservationComponent } from '../dialog-export-reservation/dialog-export-reservation.component';

@Component({
  selector: 'price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss']
})
export class PriceTableComponent implements OnInit {
  commonObject: Common; // common object
  public languageKey: string;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  helper = Helper;

  constructor(
    public translateService: TranslateService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<DialogExportReservationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.languageKey = this.commonObject?.setting?.language;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 20) {
      value = value?.substring(0, 19) + '...';
    } else if (value?.length > 35) {
      value = value?.substring(0, 33) + '...';
    }
    return value;
  }

  /**
   * checkTheExistenceOfThePriceLabel
   * @param valueTableCol
   * @returns
   */
  checkTheExistenceOfThePriceLabel(valueTableCol: string) {
    if (!this.data?.listReservationPrices || (this.data?.listReservationPrices && !this.data?.listReservationPrices?.length)) {
      return false;
    }
    return this.data.listReservationPrices.some(e => !Helper.isEmpty(e[valueTableCol]));
  }

  /**
   * setMinWidthLabelHeader
   * @returns
   */
  setMinWidthLabelHeader(): string {
    const results = [
      this.checkTheExistenceOfThePriceLabel('priceAdult'),
      this.checkTheExistenceOfThePriceLabel('priceCustom1'),
      this.checkTheExistenceOfThePriceLabel('priceCustom2'),
      this.checkTheExistenceOfThePriceLabel('priceCustom3')
    ];
    const trueCount = results.filter(result => result == true).length;
    switch (trueCount) {
      case 1:
        return '250px';
      case 2:
      case 3:
      case 4:
        return '175px';
      default:
        break;
    }
  }
}

export interface DialogData {
  listReservationPrices: Array<ReservationPrices>;
  ticketSelected: Ticket;
  targetYearAndMonth: string;
}
