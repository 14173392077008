<div id="myModal" class="modal show"> <div class="modal-dialog modal-dialog-centered"> <div class="modal-content w-h"> <div id="emergency-selection"> <div class="modal-header clearfix"> <h4 class="modal-title pull-left" jhiTranslate="ticket-editor.reservation.price-table"></h4> </div> <div class="modal-body"> <div class="element-group mb-5"> <label class="label-information" jhiTranslate="ticket-editor.reservation.individual-ticket-id-header-table"></label> <label>{{ data.ticketSelected.ticketId }}</label> </div> <div class="element-group mb-5" style="display: flex;"> <label class="label-information" jhiTranslate="ticket-editor.reservation.individual-ticket-name-header-table"></label> <label class="label-truncate" style="max-width: 600px !important;">{{ languageKey == 'en' ? data?.ticketSelected.ticketName?.en : data?.ticketSelected?.ticketName?.ja }}</label> </div> <div class="element-group mb-5" style="display: flex;"> <label class="label-information" jhiTranslate="ticket-editor.reservation.price-class-id-title"></label> <label class="label-truncate" style="max-width: 600px !important;">{{ data?.listReservationPrices && data?.listReservationPrices.length ? data.listReservationPrices[0].reservationClassId : '' }}</label> </div> <div class="element-group mb-5"> <label> {{ data.targetYearAndMonth }}</label> </div> <div style="width: 768px; overflow: auto; max-height: 500px;"> <div class="table-grid"> <div class="table-grid-head header"> <div class="table-row"> <div class="table-col title-header"> <label jhiTranslate="ticket-editor.reservation.variable-price-category" title=" {{ translateService.instant('ticket-editor.reservation.variable-price-category') }}" [ngStyle]="{
                        'min-width': setMinWidthLabelHeader()
                      }"></label> </div> <div class="table-col title-header"> <label jhiTranslate="ticket-editor.reservation.price-explanation" title=" {{ translateService.instant('ticket-editor.reservation.price-explanation') }}" [ngStyle]="{
                        'min-width': setMinWidthLabelHeader()
                      }"></label> </div> <div class="table-col title-header" *ngIf="checkTheExistenceOfThePriceLabel('priceAdult')"> <label jhiTranslate="ticket-editor.reservation.adults" title=" {{ translateService.instant('ticket-editor.reservation.adults') }}" [ngStyle]="{
                        'min-width': setMinWidthLabelHeader()
                      }">adults</label> </div> <div class="table-col title-header" *ngIf="checkTheExistenceOfThePriceLabel('priceCustom1')"> <label title=" {{ (data?.listReservationPrices)[0].priceLabelCustom1 }}" [ngStyle]="{
                        'min-width': setMinWidthLabelHeader()
                      }"> {{ (data?.listReservationPrices)[0].priceLabelCustom1 }}</label> </div> <div class="table-col title-header" *ngIf="checkTheExistenceOfThePriceLabel('priceCustom2')"> <label title=" {{ (data?.listReservationPrices)[0].priceLabelCustom2 }}" [ngStyle]="{
                        'min-width': setMinWidthLabelHeader()
                      }"> {{ (data?.listReservationPrices)[0].priceLabelCustom2 }}</label> </div> <div class="table-col title-header" *ngIf="checkTheExistenceOfThePriceLabel('priceCustom3')"> <label title=" {{ (data?.listReservationPrices)[0].priceLabelCustom3 }}" [ngStyle]="{
                        'min-width': setMinWidthLabelHeader()
                      }"> {{ (data?.listReservationPrices)[0].priceLabelCustom3 }}</label> </div> </div> </div> <div class="table-grid-body"> <div class="table-row" *ngFor="let reservationPrice of data?.listReservationPrices; let i = index"> <div class="table-col textover" style="max-width: 250px;"> <div class="textover spaces-text max-w-72" title="{{ reservationPrice?.dynamicPriceType }}"> {{ reservationPrice?.dynamicPriceType }} </div> </div> <div class="table-col table-col-price"> <div class="textover spaces-text" title="{{
                        languageKey == 'jp' ? reservationPrice.reservationPriceDescription?.ja : reservationPrice.reservationPriceDescription?.en
                      }}"> {{ languageKey == 'jp' ? reservationPrice.reservationPriceDescription?.ja : reservationPrice.reservationPriceDescription?.en }} </div> </div> <div class="table-col table-col-price" *ngIf="!helper.isEmpty(reservationPrice?.priceAdult)"> <div class="textover spaces-text" title="{{ reservationPrice?.priceAdult }}"> {{ reservationPrice?.priceAdult }} </div> </div> <div class="table-col table-col-price" *ngIf="!helper.isEmpty(reservationPrice?.priceCustom1)"> <div class="textover spaces-text" title="{{ reservationPrice?.priceCustom1 }}"> {{ reservationPrice?.priceCustom1 }} </div> </div> <div class="table-col table-col-price" *ngIf="!helper.isEmpty(reservationPrice?.priceCustom2)"> <div class="textover spaces-text" title="{{ reservationPrice?.priceCustom2 }}"> {{ reservationPrice?.priceCustom2 }} </div> </div> <div class="table-col table-col-price" *ngIf="!helper.isEmpty(reservationPrice?.priceCustom3)"> <div class="textover spaces-text" title="{{ reservationPrice?.priceCustom3 }}"> {{ reservationPrice?.priceCustom3 }} </div> </div> </div> </div> </div> </div> </div> <div class="modal-footer"> <button type="button" class="btn btn-primary" jhiTranslate="ticket-editor.reservation.ok" mat-dialog-close></button> </div> </div> </div> </div> </div> 